import React, { useState } from 'react';
import { BrowserRouter as Router, Link } from "react-router";
import "antd/dist/antd.min.css";
import './index.scss';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {

  return (
    <div className="Footer-page" style={{ position: 'absolute', bottom: '24px', width: '100%', height: '0rem' }}>
      <br />
      <div className="Footer-menu-card1">
        <div className="container">
          <div className="Footer-menu-card fp-mp" style={{ paddingBottom: '40px' }}>
            <div className="Footer-menu-item">
              <div className="Header-menu-logoName" style={{ paddingBottom: '10px', marginBottom: '10px', justifyContent: 'center' }}>
                <Link to={`/`} style={{ position: 'relative' }}>
                  <img src='/seven-tourism-logo.png' style={{ width: '110px' }} />
                </Link>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <a href='https://www.facebook.com/seventourismofficial/' target='_blank'>
                  <FacebookOutlinedIcon className='fp-social-icon' /></a>
                <a href='https://www.instagram.com/seventourism/' target='_blank'>
                  <InstagramIcon className='fp-social-icon' style={{ margin: '0px 10px' }} /></a>
                <a href='https://www.youtube.com/@seventourism' target='_blank'>
                  <YouTubeIcon className='fp-social-icon' /></a>
                <a href='https://www.linkedin.com/company/seventourism/' target='_blank'>
                  <LinkedInIcon className='fp-social-icon' style={{ margin: '0px 10px' }} /></a>
              </div>
            </div>

            <div className="Footer-menu-item">
              <div style={{ fontSize: '1rem', fontWeight: 600, padding: '5px' }}>Links</div>
              <div className="Footer-menu-SubItem"><Link to="/terms-and-conditions">Terms & Conditions</Link></div>
              <div className="Footer-menu-SubItem"><Link to="/privacy-policy">Privacy Policy</Link></div>

            </div>
            <div className="Footer-menu-item">
              <div style={{ fontSize: '1rem', fontWeight: 600, padding: '5px' }}>About Us</div>
              <div className="Footer-menu-SubItem"><Link to="/#aboutus">About</Link></div>
              <div className="Footer-menu-SubItem"><Link to="/#services">Services</Link></div>
            </div>
            <div className="Footer-menu-item">
              <div style={{ fontSize: '1rem', fontWeight: 600, padding: '5px' }}>Address</div>
              <div className="Footer-menu-SubItem">
                <div style={{ lineHeight: 1.5 }}>
                  <div>Delhi, India.</div>
                  <p>
                    <MailOutlineIcon style={{ fontSize: '22px', color: '#3e3f40' }} />
                    <span style={{ margin: '0px 10px' }}>support@seventourism.com</span>
                    <br />
                    <PhoneIcon style={{ fontSize: '22px', color: '#3e3f40' }} />
                    <span style={{ margin: '0px 10px' }}>+91 99908 28161</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="Footer-menu-card" style={{ borderTop: '1px solid #eee' }}>
            <div className="Footer-menu-item Footer-menu-bottom" style={{ justifyContent: 'space-between' }}>
              <div className='Footer-menu-subcard'>
                <div className="Footer-menu-SubItem">
                  © {new Date().getFullYear()} Seven Tourism. All rights reserved
                </div>
              </div>
              <div className='Footer-menu-subcard'>
                <div className="Footer-menu-SubItem">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  </div>
                </div>
                <div className="Footer-menu-SubItem">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
