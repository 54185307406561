import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';

function TermAndConditions() {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
      behavior: "auto"
    });
  }, [])

  return (
    <div className="Home-page">
      <div className='page-body-section' id="aboutus" style={{ background: '#f8f9fa', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className={`col-md-10`}>
              <div className='p-page-text'>
                <div className='heading'>
                  <span className='highlighted-heading'>Terms and Conditions</span>
                </div>

                <p><span style={{ fontWeight: 500 }}>Last Updated:</span> [13 Macrh, 2025]</p>
                <p>Welcome to Seven Tourism. These Terms and Conditions govern the use of our website and services. By accessing our website and using our services, you agree to abide by these terms.</p>

                <h6 style={{ fontWeight: 600 }}>Company Role & Services</h6>
                <p>Seven Tourism operates as a travel agency facilitating Umrah packages, holiday packages, visa services, and other travel-related services. We act as an intermediary between clients and third-party service providers, including airlines, hotels, visa processing agencies, and tour operators. Our role is limited to arranging and coordinating services, and we charge a service fee for our assistance.</p>

                <h6 style={{ fontWeight: 600 }}>Booking & Payment</h6>
                <p>All bookings are subject to availability and confirmation by third-party service providers.</p>
                <ul style={{ paddingLeft: '17px' }}>
                  <li>Payments must be made in full before service confirmation, unless otherwise stated.</li>
                  <li>Prices are subject to change based on supplier policies, currency fluctuations, and other external factors.</li>
                  <li>Service fees charged by Seven Tourism are non-refundable.</li>
                </ul>

                <h6 style={{ fontWeight: 600 }}>Cancellations & Refunds</h6>
                <p>Cancellation and refund policies vary based on the third-party service provider’s terms.</p>
                <ul style={{ paddingLeft: '17px' }}>
                  <li>Seven Tourism is not responsible for refunds or reimbursements; customers must directly contact the respective service providers.</li>
                  <li>Our service fee is non-refundable under all circumstances.</li>
                </ul>

                <h6 style={{ fontWeight: 600 }}>Travel Documents & Responsibility</h6>
                <p>Customers must provide accurate travel documents, including passports, visas, and other necessary permits.</p>
                <p>Seven Tourism assists in document collection and submission but is not responsible for any visa denials or delays by embassies or consulates.</p>
                <ul style={{ paddingLeft: '17px' }}>
                  <li>Users are responsible for ensuring they have valid passports, visas, and travel documents.</li>
                </ul>

                <h6 style={{ fontWeight: 600 }}>Liability Disclaimer</h6>
                <p>We do not own or control the services provided by third-party vendors and are not liable for cancellations, delays, or service deficiencies.</p>
                <p>Customers are responsible for verifying travel restrictions, health advisories, and other regulations before booking.</p>
                <h6 style={{ fontWeight: 600 }}>Privacy Policy</h6>
                <p>Our <Link to={`/privacy-policy`}>Privacy Policy</Link>, as detailed below, governs how we collect, use, and protect your personal information.</p>

                <h6 style={{ fontWeight: 600 }}>Website Use</h6>
                <ul style={{ paddingLeft: '17px' }}>
                  <li>Users must provide accurate information when filling out forms on our website.</li>
                  <li>Unauthorized use of this website, including fraud or illegal transactions, is prohibited.</li>
                </ul>

                {/* <h5>Governing Law</h5>
        <p>These Terms & Conditions are governed by the laws of India. Any disputes shall be subject to the jurisdiction of Indian courts.</p> */}

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

  );
}

export default TermAndConditions;
