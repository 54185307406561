import React, { useState, useEffect, Fragment, useRef } from 'react';
import './index.scss';
import Button from '@mui/joy/Button';
import { useCreateInquiriesMutation } from '../../../services/inquiries';
import { useForm } from "react-hook-form";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import ReCAPTCHA from "react-google-recaptcha";
import 'react-phone-number-input/style.css'


function Contactus() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [createinquiry, createresult] = useCreateInquiriesMutation()
  

  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)
  const [isAlready, setAlready] = useState(false)
  const recaptchaRef = React.useRef();

  async function createInquiryFrom(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (isValidPhoneNumber(getPhone)) {
      createinquiry({
        name: data.name,
        email: data.email,
        message: data.message,
        phone: getPhone,
        captcha_token: token,
      })
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])


  return (
    <div className="Home-page">

      <div className='page-body-section' style={{ background: '#f8f9fa', position: 'relative', padding: 0 }}>
        <div className="row" style={{ justifyContent: 'center' }}>
          <div className={`col-md-7 pg-title-section`}>
            <div style={{ position: 'relative' }}>
              <h1 className='pb-title'>We're Always Glad to Help You!</h1>
            </div>
          </div>
        </div>
      </div>

      <div className='section' style={{ background: '#fff', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>
                  <svg width="55px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-shield">
                    <path d="M12 2l9 4.5v5c0 5.6-3.8 10.74-9 12-5.2-1.26-9-6.4-9-12v-5L12 2z"></path>
                    <polyline points="9 12 12 15 15 10"></polyline>
                  </svg>
                </div>
                <div style={{ fontSize: '1.5rem', padding: '10px' }}>Trust</div>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>💡</div>
                <div style={{ padding: '10px', fontSize: '1.5rem' }}>Expertise</div>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>✔️</div>
                <div style={{ padding: '10px', fontSize: '1.5rem' }}>Reliability</div>
              </div>
            </div>
            <div className={`col-md-3`}>
              <div style={{ textAlign: 'center' }}>
                <div style={{ fontSize: '3rem' }}>🌟</div>
                <div style={{ padding: '10px', fontSize: '1.5rem' }}>Commitment </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className='page-body-section' style={{ background: '#fff', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-7 pg-form-section`}>
              <div style={{ position: 'relative', width: '100%' }}>
            
                {createresult?.isSuccess ?
                <div className="row">
                  <div className={`col-md-12`}>
                    <div className='alert alert-success'
                      style={{ width: '100%', textAlign: 'center', marginTop: '15px' }}>
                      {createresult?.data?.message}
                    </div>
                  </div>
                </div> :

                <form className='pp-form-de' onSubmit={handleSubmit(createInquiryFrom)}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6Lf-zcQcAAAAALutW8iooYsZt8YamnMGT7Q_zn1w"
                  />
                  <div style={{ position: 'relative', width: '100%' }}>
                    <div className="row">
                      <div className={`col-md-12`}>
                        <h1 style={{ fontWeight: 600, fontSize: '2.2rem', marginTop: '20px' }}>Get in touch</h1>
                      </div>
                      <div className={`col-md-12`}>
                        <div className='form-group'>
                          <input
                            type='text'
                            id="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            {...register('name', { required: true, maxLength: { value: 50, message: "Max 50 characters" } })}
                            className='form-control'
                            placeholder='Your Name'
                            style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }} />
                          {errors.name && <p><small style={{ color: 'tomato' }}>{errors.name.message}</small></p>}
                        </div>
                      </div>

                      <div className={`col-md-6`}>
                        <div className='form-group'>
                          <PhoneInput
                            style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry={'IN'}
                            value={getPhone}
                            onChange={setPhone}
                          />
                          {getPhoneError &&
                            <>
                              {getPhone && isValidPhoneNumber(getPhone) ? '' :
                                <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                              }
                            </>
                          }
                        </div>
                      </div>

                      <div className={`col-md-6`}>
                        <div className='form-group'>
                          <input
                            type='text'
                            id="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            {...register('email', { required: true, maxLength: { value: 50, message: "Max 50 characters" } })}
                            className='form-control'
                            placeholder='Your Email'
                            style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }} />
                          {errors.email && <p><small style={{ color: 'tomato' }}>{errors.email.message}</small></p>}
                        </div>
                      </div>

                      <div className={`col-md-12`}>
                        <div className='form-group'>
                          <textarea
                            id="message"
                            aria-invalid={errors.message ? "true" : "false"}
                            {...register('message', { required: true, maxLength: { value: 200, message: "Max 200 characters" } })}
                            className='form-control'
                            placeholder='Message'
                            style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem' }}>
                          </textarea>
                          {errors.message && <p><small style={{ color: 'tomato' }}>{errors.message.message}</small></p>}
                        </div>
                      </div>

                      <div className={`col-md-12`}>
                        <div className='form-group'>
                          <Button
                            disabled={createresult?.isLoading}
                            variant='soft'
                            color='primary'
                            size='lg'
                            type='submit'
                            style={{ width: '100%' }}>
                            {createresult?.isLoading ? 'Please wait..' : 'Submit'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              }
                



              </div>
            </div>

          </div>
        </div>
      </div>



    </div>

  );
}

export default Contactus;
