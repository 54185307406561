import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import localReducer from "../reducers/localReducer";

import { productsApi } from '../services/products';
import { inquiryApi } from '../services/inquiries';

export const store = configureStore({
  reducer: {
    localStorage: localReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [inquiryApi.reducerPath]: inquiryApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productsApi.middleware).concat(inquiryApi.middleware)
})
setupListeners(store.dispatch)
