import React from "react";
import { BrowserRouter as Router, Switch, Route, Routes } from "react-router";

import NotFound from './NotFound';
import { useSelector } from 'react-redux'
import WebPage from "./Private/WebPage";
import Home from "./web/Pages/Home";
import Contactus from "./web/Pages/Contactus";
import BookNow from "./web/Pages/BookNow";
import TermAndConditions from "./web/Pages/TermAndConditions";
import PrivacyPolicy from "./web/Pages/PrivacyPolicy"
import ProductPage from "./web/Pages/ProductPage";
import './App.css';

function App() {
  const { userRole, adminAccess } = useSelector((state) => state.localStorage);

  return (
    <div className="App">

      <Router>
        <Routes>
          {/* Public Routes */}
          <Route index element={<WebPage component={Home} />} />

          <Route path="/product/:id" element={<WebPage component={ProductPage} />} />

          <Route path="/book-now" element={<WebPage component={BookNow} />} />
          <Route path="/contact" element={<WebPage component={Contactus} />} />
          <Route path="/terms-and-conditions" element={<WebPage component={TermAndConditions} />} />
          <Route path="/privacy-policy" element={<WebPage component={PrivacyPolicy} />} />
          <Route path="*" element={<WebPage component={NotFound} />} />
          {/* <Route path="/admin">
            <Route index element={<Admin component={Dashboard} />} />
          </Route> */}
        </Routes>
      </Router>

    </div>
  );
}

export default App;
