import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

function authHeader(data) {
  return {
    'Accept': 'application/json',
    'Content-type': 'application/json; charset=UTF-8',
  }
}

export const productsApi = createApi({
  reducerPath: 'productsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Products'],
  // keepUnusedDataFor: 1,
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    fetchSubscriptions: builder.query({
      query: (data) => {
        return {
          url: `subscriptions`,
          method: 'GET',
          headers: {
            ...authHeader(data)
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Products'],
    }),

    fetchProduct: builder.query({
      query: (data) => {
        return {
          url: `api/products/${data.id}`,
          method: 'GET',
          headers: {
            ...authHeader(data)
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Products'],
    }),

    fetchProducts: builder.query({
      query: (data) => {
        return {
          url: `api/products?type=0`,
          method: 'GET',
          headers: {
            ...authHeader(data)
          },
        }
      },
      // keepUnusedDataFor: 1,
      providesTags: ['Products'],
    }),

  }),

})

export const { useFetchSubscriptionsQuery, useFetchProductQuery, useFetchProductsQuery } = productsApi