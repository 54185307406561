import React, { Component } from 'react';
import { Redirect, useNavigate } from "react-router";
import './index.scss';

function NotFound() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="tabel_cards">
      <div className="row card-list-row">
        <div className="col-md-12">
          <div className="updateButton">
            <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
