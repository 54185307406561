import React, { useState, useEffect, Fragment, useRef } from 'react';
import './index.scss';
import { BrowserRouter as Router, Route, Link, useNavigate, useParams, useLocation } from "react-router";
import Button from '@mui/joy/Button';
import { useForm } from "react-hook-form";
// import Skeleton from '@material-ui/lab/Skeleton';
import Skeleton from '@mui/material/Skeleton';
import {
  useFetchProductQuery
} from "../../../services/products";
import Typography from '@mui/joy/Typography';
import { useCreateInquiriesMutation } from '../../../services/inquiries';

import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import ReCAPTCHA from "react-google-recaptcha";
import 'react-phone-number-input/style.css'

function ProductPage() {
  const { id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const queryParams = React.useMemo(
    () => ({
      id,
    }),
    [id]
  );

  const [createinquiry, createresult] = useCreateInquiriesMutation()

  const dataInfo = useFetchProductQuery(
    queryParams,
    { count: 1 },
    { refetchOnMountOrArgChange: false }
  );
  const result = dataInfo?.data?.result;

  const image = result?.images?.split(',')


  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)
  const [isAlready, setAlready] = useState(false)
  const recaptchaRef = React.useRef();

  async function createInquiryFrom(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (isValidPhoneNumber(getPhone)) {
      createinquiry({
        name: data.name,
        email: data.email,
        message: data.message,
        phone: getPhone,
        captcha_token: token,
      })
    } else {
      setPhoneError(true)
    }
  }

  // useEffect(() => {
  //   if (createresult?.isSuccess) {
  //   }
  // }, [createresult]);


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  function formatPrice(price, currency) {
    const numericPrice = Number(price);
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency ? currency : 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })?.format(numericPrice);
  };


  return (
    <div className="Home-page">

      <div className='page-body-section' id="aboutus" style={{ background: '#fcfcfc', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-6`}>
              <div className='p-about-text'>
                <div className='heading' style={{ fontSize: '2.1rem' }}>
                  {dataInfo?.isLoading ?
                    <Skeleton animation="wave" variant="rounded" width={'70%'} height={20} /> :
                    <span className='highlighted-heading'>{result?.name}</span>
                  }
                </div>
                {dataInfo?.isLoading ?
                  <>
                    <Skeleton animation="wave" style={{ marginBottom: '10px' }} variant="rounded" width={'50%'} height={15} />
                    <Skeleton animation="wave" style={{ marginBottom: '10px' }} variant="rounded" width={'35%'} height={15} />
                    <Skeleton animation="wave" style={{ marginBottom: '10px' }} variant="rounded" width={'35%'} height={15} />
                    <Skeleton animation="wave" style={{ marginBottom: '10px' }} variant="rounded" width={'35%'} height={15} />
                  </>
                  :
                  <div className='p-html-details' dangerouslySetInnerHTML={{ __html: result?.details }} />
                }
              </div>
            </div>
            <div className={`col-md-6`}>
              <div className="row">
                <div className={`col-md-12`}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', height: '60px' }}>
                    <div style={{ marginBottom: '15px' }}>
                      <Typography level="body-xs">Starting price:</Typography>
                      <Typography level="title-lg">
                        {dataInfo?.isLoading ?
                          <Skeleton animation="wave" variant="rounded" width={'100%'} height={15} /> :
                          formatPrice(result?.price, result?.currency)
                        }
                      </Typography>
                      {/* <Typography level="body-sm"></Typography> */}
                    </div>
                    <Button
                      variant="solid"
                      size="md"
                      color="primary"
                      sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
                    >
                      Book
                    </Button>
                  </div>
                  {dataInfo?.isLoading ?
                    <Skeleton animation="wave" variant="rounded" width={'100%'} height={200} /> :
                    <img style={{ height: '200px', width: '100%', objectFit: 'cover' }} src={image} />
                  }
                </div>
              </div>

              {createresult?.isSuccess ?
                <div className="row">
                  <div className={`col-md-12`}>
                    <div className='alert alert-success'
                      style={{ width: '100%', textAlign: 'center', marginTop: '15px' }}>
                      {createresult?.data?.message}
                    </div>
                  </div>
                </div> :

                <form className='pp-form-de' onSubmit={handleSubmit(createInquiryFrom)}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6Lf-zcQcAAAAALutW8iooYsZt8YamnMGT7Q_zn1w"
                  />
                  <div style={{ position: 'relative', width: '100%' }}>
                    <div className="row">
                      <div className={`col-md-12`}>
                        <h1 style={{ fontWeight: 600, fontSize: '1.7rem', marginTop: '20px' }}>Get in touch</h1>
                      </div>
                      <div className={`col-md-12`}>
                        <div className='form-group'>
                          <input
                            type='text'
                            id="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            {...register('name', { required: true, maxLength: { value: 50, message: "Max 50 characters" } })}
                            className='form-control'
                            placeholder='Your Name'
                            style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }} />
                          {errors.name && <p><small style={{ color: 'tomato' }}>{errors.name.message}</small></p>}
                        </div>
                      </div>

                      <div className={`col-md-6`}>
                        <div className='form-group'>
                          <PhoneInput
                            style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }}
                            international
                            countryCallingCodeEditable={false}
                            defaultCountry={'IN'}
                            value={getPhone}
                            onChange={setPhone}
                          />
                          {getPhoneError &&
                            <>
                              {getPhone && isValidPhoneNumber(getPhone) ? '' :
                                <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                              }
                            </>
                          }
                        </div>
                      </div>

                      <div className={`col-md-6`}>
                        <div className='form-group'>
                          <input
                            type='text'
                            id="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            {...register('email', { required: true, maxLength: { value: 50, message: "Max 50 characters" } })}
                            className='form-control'
                            placeholder='Your Email'
                            style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem', height: '60px' }} />
                          {errors.email && <p><small style={{ color: 'tomato' }}>{errors.email.message}</small></p>}
                        </div>
                      </div>

                      <div className={`col-md-12`}>
                        <div className='form-group'>
                          <textarea
                            id="message"
                            aria-invalid={errors.message ? "true" : "false"}
                            {...register('message', { required: true, maxLength: { value: 200, message: "Max 200 characters" } })}
                            className='form-control'
                            placeholder='Message'
                            style={{ background: '#f8f9fa', border: '1px solid #e2e2e2', color: '#000', padding: '1rem' }}>
                          </textarea>
                          {errors.message && <p><small style={{ color: 'tomato' }}>{errors.message.message}</small></p>}
                        </div>
                      </div>

                      <div className={`col-md-12`}>
                        <div className='form-group'>
                          <Button
                            disabled={createresult?.isLoading}
                            variant='soft'
                            color='primary'
                            size='lg'
                            type='submit'
                            style={{ width: '100%' }}>
                            {createresult?.isLoading ? 'Please wait..' : 'Submit'}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default ProductPage;
