import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  userId: localStorage.getItem("userId") ? localStorage.getItem('userId') : '',
  userEmail: localStorage.getItem("userEmail") ? localStorage.getItem('userEmail') : '',
  userToken: localStorage.getItem("userToken") ? localStorage.getItem('userToken') : '',
  adminAccess: localStorage.getItem("adminAccess") ? JSON.parse(localStorage.getItem('adminAccess')) : '',
  accessKey: localStorage.getItem("accessKey") ? localStorage.getItem('accessKey') : '',
  languageCode: localStorage.getItem("languageCode") ? localStorage.getItem('languageCode') : '',
};

const localReducer = createSlice({
  name: "localStorage",
  initialState,
  reducers: {
    addToLocalStorage(state, action) {
      state.userId = action.payload?.userId
      state.userEmail = action.payload?.userEmail
      state.userToken = action.payload?.userToken
      state.adminAccess = action.payload?.adminAccess
      state.accessKey = action.payload?.accessKey
      state.languageCode = action.payload?.languageCode
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userEmail", state.userEmail)
      localStorage.setItem("userToken", state.userToken)
      localStorage.setItem("adminAccess", JSON.stringify(state.adminAccess));
      localStorage.setItem("accessKey", state.accessKey)
      localStorage.setItem("languageCode", state.languageCode)
    },
    removeToLocalStorage(state, action) {
      state.userId = ''
      state.userEmail = ''
      state.userToken = ''
      state.adminAccess = ''
      state.accessKey = ''
      state.languageCode = ''
      localStorage.setItem("userId", state.userId);
      localStorage.setItem("userEmail", state.userEmail)
      localStorage.setItem("userToken", state.userToken)
      localStorage.setItem("adminAccess", JSON.stringify(state.adminAccess));
      localStorage.setItem("accessKey", state.accessKey)
      localStorage.setItem("languageCode", state.languageCode)
    },
  },
  extraReducers: {}
});

export const { addToLocalStorage, removeToLocalStorage } = localReducer.actions;

export default localReducer.reducer;
