import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useNavigate, Redirect, useLocation, useParams } from "react-router";
import { useForm } from "react-hook-form";
import './index.scss';

function PrivacyPolicy() {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="Home-page">
      <div className='page-body-section' id="aboutus" style={{ background: '#f8f9fa', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div className={`col-md-10`}>
              <div className='p-page-text'>
                <div className='heading'>
                  <span className='highlighted-heading'>Privacy policy</span>
                </div>
                <p><span style={{ fontWeight: 500 }}>Last Updated:</span> [13 March 2025]</p>
                <p>Seven Tourism respects your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.</p>

                <h6 style={{ fontWeight: 600 }}>Information We Collect</h6>
                <ul style={{ paddingLeft: '17px' }}>
                  <li>Name, email address, and phone number (collected via website forms).</li>
                  <li>Passport details, visa documents, and travel-related information.</li>
                  <li>Payment details for service processing.</li>
                  <li>Other information voluntarily provided by you.</li>
                </ul>

                <h6 style={{ fontWeight: 600 }}>How We Use Your Information</h6>
                <ul style={{ paddingLeft: '17px' }}>
                  <li>To process bookings and service requests.</li>
                  <li>To communicate with customers regarding their travel plans.</li>
                  <li>To comply with legal and regulatory requirements.</li>
                  <li>To improve our services and website experience.</li>
                </ul>

                <h6 style={{ fontWeight: 600 }}>Data Sharing & Disclosure</h6>
                <ul style={{ paddingLeft: '17px' }}>
                  <li>We share necessary information with third-party service providers (e.g., airlines, visa agencies, tour operators) to fulfill your bookings.</li>
                  <li>We do not sell, rent, or trade your personal information.</li>
                  <li>Information may be disclosed if required by law or government authorities.</li>
                </ul>

                <h6 style={{ fontWeight: 600 }}>Data Security</h6>
                <p>We implement security measures to protect your data from unauthorized access or misuse. However, no internet-based system is entirely secure, and we cannot guarantee absolute data security.</p>

                <h6 style={{ fontWeight: 600 }}>Your Rights</h6>
                <ul style={{ paddingLeft: '17px' }}>
                  <li>You may request access, modification, or deletion of your personal data by contacting us.</li>
                  <li>You can opt out of promotional communications at any time.</li>
                </ul>

                <h6 style={{ fontWeight: 600 }}>Cookies & Tracking</h6>
                <p>Our website may use cookies to enhance user experience and collect analytics. You can disable cookies in your browser settings if preferred.</p>

                <h6 style={{ fontWeight: 600 }}>Changes to Privacy Policy</h6>
                <p>Seven Tourism reserves the right to update this Privacy Policy. Any changes will be posted on our website with a revised date.</p>
                <h6 style={{ fontWeight: 600 }}>Contact Us</h6>
                <p>For questions or concerns, contact us at [info@seventourism.com].</p>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default PrivacyPolicy;
