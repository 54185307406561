import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from "react-router";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from 'react-responsive'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


function Header() {
  const { userId, userToken, userEmail, languageCode, countryCode, isOffer } = useSelector((state) => state.localStorage);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  const { t } = useTranslation()
  const dispatch = useDispatch();

  const [getLogoSize, setLogoSize] = useState("110px");
  const [isScroll, setScroll] = useState(false);

  const listenScrollEvent = () => {
    window.scrollY > 15 ? setScroll(true) : setScroll(false);
    window.scrollY > 15 ? setLogoSize("110px") : setLogoSize("110px");
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  function menuList() {
    return (
      <>

        <Link
          style={{ display: 'block', color: window.location.pathname === `/` ? '#1d84cf' : '#2d2f30' }}
          className="nav-menu-item"
          smooth
          to={window.location.pathname == '/' ? `/#home` : '/'}>
          <span className="mobile-menu-item-size">{t('Home')}</span>
        </Link>

        <Link
          style={{ display: 'block', color: window.location.pathname === `/services` ? '#1d84cf' : '#2d2f30' }}
          className="nav-menu-item"
          smooth
          to={`/#services`}>
          <span className="mobile-menu-item-size">{t('Our Services')}</span>
        </Link>

        <Link
          style={{ display: 'block', color: window.location.pathname === `/aboutus` ? '#1d84cf' : '#2d2f30' }}
          className="nav-menu-item"
          smooth
          to={`/#aboutus`}>
          <span className="mobile-menu-item-size">{t('About Us')}</span>
        </Link>

        <Link
          style={{ display: 'block', color: window.location.pathname === `/contact` ? '#1d84cf' : '#2d2f30' }}
          className="nav-menu-item"
          to={`/contact`}>
          <span className="mobile-menu-item-size">{t('Contact')}</span>
        </Link>

      </>
    )
  }

  const [getMobMenu, setMobMenu] = useState(false);
  function getMobilemenu() {
    return (
      <div style={{
        width: '235px',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 9999,
        background: '#000',
        height: '100%',
        padding: '10px',
        transition: "all 0.5s",
        borderRight: '0.5px solid #f7ee23'
      }}>
        <div style={{ position: 'relative' }}>
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0, zIndex: 1111 }}
            onClick={(() => setMobMenu(false))}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
          {menuList()}
        </div>
      </div>
    )
  }


  const list = (anchor) => (
    <Box
      style={{ height: '100%' }}
      className="side-dialogue-section"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Link
        style={{ display: 'block', color: '#2d2f30', padding: '6px 15px', textTransform: 'uppercase' }}
        className="nav-menu-item"
        to={window.location.pathname == '/' ? `/#home` : '/'}>
        <span className="mobile-menu-item-size">Home</span>
      </Link>
      <Link
        style={{ display: 'block', color: '#2d2f30', padding: '6px 15px', textTransform: 'uppercase' }}
        className="nav-menu-item"
        to={`/#services`}>
        <span className="mobile-menu-item-size">Our Services</span>
      </Link>

      <Link
        style={{ display: 'block', color: '#2d2f30', padding: '6px 15px', textTransform: 'uppercase' }}
        className="nav-menu-item"
        to={`/#aboutus`}>
        <span className="mobile-menu-item-size">About Us</span>
      </Link>

      <Link
        style={{ display: 'block', color: '#2d2f30', padding: '6px 15px', textTransform: 'uppercase' }}
        className="nav-menu-item"
        to={`/contact`}>
        <span className="mobile-menu-item-size">Contact</span>
      </Link>
    </Box>
  );

  const [getcartDrawer, cartDrawer] = useState();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    cartDrawer(getcartDrawer)
  }, [getcartDrawer]);

  const [getOffer, setOffer] = useState(false);

  function hideOffer() {
    setOffer(false)
  }
  useEffect(() => {
    if (!isOffer) {
      setOffer(true)
    }
  }, []);

  return (
    <>

      <div className="Header-section"
        style={{
          background: isScroll ? '#f8f9f9' : '#f8f9f9',
          top: isScroll ? '0px' : '0px',
        }}>
        {isMobcreen && getMobMenu &&
          getMobilemenu()
        }
        <div className="Header-menu">
          <div className="container">
            <div className={`Header-menu-item`}>
              <div className='Header-menu-card'>
                <div className="Header-menu-logoName" style={{padding: 0}}>
                  <Link to={window.location.pathname == '/' ? `/#home` : '/'}>
                    <img src='/seven-tourism-logo.png' style={{ width: '110px' }} />
                  </Link>
                </div>

                <div className={`Header-menu-account`}>
                  {!isMobcreen &&
                    <>
                      {menuList()}
                    </>
                  }
                  {isMobcreen &&
                    <div>
                      {[document.body.dir === 'rtl' ? 'right' : 'left'].map((anchor) => (
                        <IconButton key={anchor} onClick={toggleDrawer(anchor, true)}>
                          <MenuIcon />
                        </IconButton>
                      ))}
                    </div>
                  }
                </div>
              </div>
              <div className={`Header-menu-account`}>
                <Button
                  className='nav-menu-item'
                  style={{ borderRadius: '100px', margin: '0px 10px', }}
                  component={Link}
                  to={`#`}
                  variant='plain'
                  size='sm'
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <AccountCircleIcon style={{ color: '#3e3f40', fontSize: '1.5rem' }} />
                    <span className='mobile-hide' style={{ color: '#3e3f40', margin: '0px 5px' }}>Login</span>
                  </div>
                </Button>

                <a
                  style={{ padding: 0, margin: 0, background: '#fff0', border: 'none' }}
                  className='nav-menu-item alert alert-success'
                  href='https://api.whatsapp.com/send?phone=919990828161 &amp;text=Hi'
                  target='_blank'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <WhatsAppIcon style={{ fontSize: '1.5rem' }} />
                    <span className='mobile-hide' style={{ margin: '0px 5px' }}>Chat</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <React.Fragment>
        {[document.body.dir === 'rtl' ? 'right' : 'left'].map((anchor) => (
          <Drawer
            className="side-menu-section side-scroll-menu"
            key={anchor}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 1, padding: '0px', textAlign: document.body.dir === 'rtl' ? 'left' : 'right' }}>
              <ModalClose id="close-icon" sx={{ position: 'initial', color: '#eeed31' }} />
            </div>
            <div className='thisisississisisisisi' style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '6px 15px', height: '100%' }}>
              {list(anchor)}
            </div>
          </Drawer >
        ))
        }
      </React.Fragment >
    </>
  );
}

export default Header;
